gsap.registerPlugin(ScrollTrigger);

// Lenis Smooth Scroll
const lenis = new Lenis();

function raf(time: number) {
    lenis.raf(time);
    requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

lenis.on('scroll', ScrollTrigger.update)

gsap.ticker.add((time) => {
    lenis.raf(time * 1000)
})

gsap.ticker.lagSmoothing(0)

//
const hamburgerBtn = document.querySelector("#hamburgerBtn");
const navbar = document.querySelector(".navbar");
const buttonLink = document.querySelector(".button-link");
const fadeElements = document.querySelectorAll(".has-fade");
const body = document.querySelector("body");

let prevScrollPos = 0; // Initialize the previous scroll position
const navbarHeight = (<HTMLElement>navbar).offsetHeight; // Get the height of the navbar

window.onscroll = () => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos > prevScrollPos) {
        if (currentScrollPos > navbarHeight) {
            navbar.classList.add("scrolled");
            buttonLink.classList.add("scrolled");
        }
    } else {
        if (currentScrollPos <= navbarHeight) {
            navbar.classList.remove("scrolled");
            buttonLink.classList.remove("scrolled");
        }
    }
    prevScrollPos = currentScrollPos;
};

hamburgerBtn.addEventListener('click', () => {
    let open = navbar.classList.toggle("open");
    if (open) {
        body.classList.add("lock-scroll");
        fadeElements.forEach((element) => {
            element.classList.remove("fade-out");
            element.classList.add("fade-in");
        });
    } else {
        body.classList.remove("lock-scroll");
        fadeElements.forEach((element) => {
            element.classList.remove("fade-in");
            element.classList.add("fade-out");
        });
    }

})

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        navbar.classList.remove("open");
        fadeElements.forEach((element) => {
            element.classList.remove("fade-in");
            element.classList.add("fade-out");
        });
        navbar.classList.add("hidden");
        body.classList.remove("lock-scroll");
        if (this.getAttribute('href') !== '#home') {
            lenis.scrollTo(this.getAttribute('href'));
        } else {
            lenis.scrollTo(0);
        }
    });
});

const slider = document.querySelector('.home-slider');
const next = document.querySelector('.next');
const prev = document.querySelector('.prev');

next.addEventListener('click', () => {
    const items = document.querySelectorAll('.item');
    slider.append(items[0]);
}, false);

prev.addEventListener('click', () => {
    const items = document.querySelectorAll('.item');
    slider.prepend(items[items.length-1]);
}, false);


//explore Section
$(function() {
    const $cont = $('.cont');
    const $slider = $('.slider');
    const $nav = $('.nav');
    const winW = $(window).width();
    const animSpd = 750; // Change also in CSS
    const distOfLetGo = winW * 0.2;
    let curSlide = 1;
    let animation = false;
    let autoScrollVar = true;
    let diff = 0;

    // Generating slides
    let arrCities = ['Guangzhou', 'Dubai', ]; // Change the number of slides in CSS also
    let numOfCities = arrCities.length;
    let arrCitiesDivided = [];

    arrCities.map((city) => {
        let length = city.length;
        let letters = Math.floor(length / 4);
        let exp = new RegExp(".{1," + letters + "}", "g");

        arrCitiesDivided.push(city.match(exp));
    });

    let generateSlide = function(city: number) {
        let frag1 = $(document.createDocumentFragment());
        let frag2 = $(document.createDocumentFragment());
        const numSlide = arrCities.indexOf(arrCities[city]) + 1;
        const firstLetter = arrCitiesDivided[city][0].charAt(0);

        const $slide =
            $(`<div data-target="${numSlide}" class="slide slide--${numSlide}">
							<div class="slide__darkbg slide--${numSlide}__darkbg"></div>
							<div class="slide__text-wrapper slide--${numSlide}__text-wrapper"></div>
						</div>`);

        const letter =
            $(`<div class="slide__letter slide--${numSlide}__letter">
							${firstLetter}
						</div>`);

        for (let i = 0, length = arrCitiesDivided[city].length; i < length; i++) {
            const text =
                $(`<div class="slide__text slide__text--${i + 1}">
								${arrCitiesDivided[city][i]}
							</div>`);
            frag1.append(text);
        }

        const navSlide = $(`<li data-target="${numSlide}" class="nav__slide nav__slide--${numSlide}"></li>`);
        frag2.append(navSlide);
        $nav.append(frag2);

        $slide.find(`.slide--${numSlide}__text-wrapper`).append(letter).append(frag1);
        $slider.append($slide);

        if (arrCities[city].length <= 4) {
            $('.slide--'+ numSlide).find('.slide__text').css("font-size", "12vw");
        }
    };

    for (let i = 0, length = numOfCities; i < length; i++) {
        generateSlide(i);
    }

    $('.nav__slide--1').addClass('nav-active');

    // Navigation
    function bullets(dir: string | number) {
        $('.nav__slide--' + curSlide).removeClass('nav-active');
        $('.nav__slide--' + dir).addClass('nav-active');
    }

    function timeout() {
        animation = false;
    }

    function pagination(direction: number) {
        animation = true;
        diff = 0;
        $slider.addClass('animation');
        $slider.css({
            'transform': 'translate3d(-' + ((curSlide - direction) * 100) + '%, 0, 0)'
        });

        $slider.find('.slide__darkbg').css({
            'transform': 'translate3d(' + ((curSlide - direction) * 50) + '%, 0, 0)'
        });

        $slider.find('.slide__letter').css({
            'transform': 'translate3d(0, 0, 0)',
        });

        $slider.find('.slide__text').css({
            'transform': 'translate3d(0, 0, 0)'
        });
    }

    function navigateRight() {
        if (!autoScrollVar) return;
        if (curSlide >= numOfCities) return;
        pagination(0);
        setTimeout(timeout, animSpd);
        bullets(curSlide + 1);
        curSlide++;
    }

    function navigateLeft() {
        if (curSlide <= 1) return;
        pagination(2);
        setTimeout(timeout, animSpd);
        bullets(curSlide - 1);
        curSlide--;
    }

    function toDefault() {
        pagination(1);
        setTimeout(timeout, animSpd);
    }

    // Events
    $(document).on('mousedown touchstart', '.slide', function(e) {
        if (animation) return;
        let target = +$(this).attr('data-target');
        let startX = e.pageX || e.originalEvent["touches"][0].pageX;
        $slider.removeClass('animation');

        $(document).on('mousemove touchmove', function(e) {
            let x = e.pageX || e.originalEvent["touches"][0].pageX;
            diff = startX - x;
            if (target === 1 && diff < 0 || target === numOfCities && diff > 0) return;

            $slider.css({
                'transform': 'translate3d(-' + ((curSlide - 1) * 100 + (diff / 30)) + '%, 0, 0)'
            });

            $slider.find('.slide__darkbg').css({
                'transform': 'translate3d(' + ((curSlide - 1) * 50 + (diff / 60)) + '%, 0, 0)'
            });

            $slider.find('.slide__letter').css({
                'transform': 'translate3d(' +  (diff / 60) + 'vw, 0, 0)',
            });

            $slider.find('.slide__text').css({
                'transform': 'translate3d(' + (diff / 15) + 'px, 0, 0)'
            });
        })
    })

    $(document).on('mouseup touchend', function(e) {
        $(document).off('mousemove touchmove');

        if (animation) return;

        if (diff >= distOfLetGo) {
            navigateRight();
        } else if (diff <= -distOfLetGo) {
            navigateLeft();
        } else {
            toDefault();
        }
    });

    $(document).on('click', '.nav__slide:not(.nav-active)', function() {
        let target = +$(this).attr('data-target');
        bullets(target);
        curSlide = target;
        pagination(1);
    });

    $(document).on('click', '.side-nav', function() {
        let target = $(this).attr('data-target');

        if (target === 'right') navigateRight();
        if (target === 'left') navigateLeft();
    });

    $(document).on('keydown', function(e) {
        if (e.which === 39) navigateRight();
        if (e.which === 37) navigateLeft();
    });

});

document.addEventListener("DOMContentLoaded", function() {
    let bar = document.querySelector('#navbar');
    let section = document.getElementById('explore');
    let sectionTop = section.offsetTop;
    let sectionBottom = section.offsetTop + section.offsetHeight;

    window.addEventListener('scroll', function () {
        if (window.pageYOffset >= sectionTop && window.pageYOffset <= sectionBottom) {
            bar.classList.add('hidden');
        } else {
            bar.classList.remove('hidden');
        }
    });
});

let swiper = new Swiper(".mySwiper", {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

const year = new Date().getFullYear();
document.getElementById('year').innerText = String(year);